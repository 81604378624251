
<template>
  <div>
    <img
      class="header-back-btn"
      src="@/assets/img/header-back-btn.png"
      alt=""
      @click="backLogon"
    />
    <div class="login">
      <img class="logo" src="@/assets/img/logo.png" alt="" />
      <div class="title">
        {{ title }}
      </div>
      <form class="login-content" @submit.prevent="onSubmit">
        <div class="input-block">
          <div>Nomor Hp</div>
          <div class="input_box">
            <input
              v-model.trim="phone"
              type="tel"
              autofocus
              placeholder="Silakan masukkan nomor HP denganawalan 08"
              maxlength="13"
            />
          </div>
        </div>
        <div v-if="showPasswordInput" class="input-block">
          <div>Password</div>
          <div class="input_box">
            <input
              v-model.trim="password"
              maxlength="13"
              type="text"
              class="password"
              :placeholder="tipText"
            />
          </div>
        </div>
        <div v-if="showPasswordInput" class="input-block">
          <div>Konfirmasi password</div>
          <div class="input_box">
            <input
              v-model.trim="password1"
              maxlength="13"
              type="teltext"
              class="password"
              :placeholder="tipText"
            />
          </div>
        </div>
        <div class="input-block">
          <div>Kode verifikasi robot</div>
          <div class="img-code">
            <input
              v-model="imgVerifyCode"
              placeholder="Silakan masukkan kodeverifikasi robot"
              type="tel"
              maxlength="4"
            />
            <img
              v-show="phone.length > 8"
              class="code"
              ref="verifyImg"
              @click="refreshVerifyCode"
            />
            <div v-show="phone.length <= 8" class="code"></div>
            <img
              class="refresh"
              src="@/assets/img/refresh-icon.png"
              @click="refreshVerifyCode"
            />
          </div>
        </div>
        <div class="SMS-code">
          <div class="title">Kode verifikasi SMS</div>
          <input v-model="verifyCode" type="tel" maxlength="4" />
          <div class="box">
            <span>{{ verifyCode[0] }}</span>
            <span>{{ verifyCode[1] }}</span>
            <span>{{ verifyCode[2] }}</span>
            <span>{{ verifyCode[3] }}</span>
          </div>
          <div class="verify-code-btn" @click="getVerifyCode">
            <span v-if="isGetCode" style="color: #5f77f3"
              >Dapatkan kode verifikasi</span
            >
            <span v-else>Dapatkan kembali({{ countDown }}s)</span>
          </div>
        </div>
        <!-- <button
          type="submit"
          :class="['confirm-btn', showPasswordInput ? 'near' : 'far']"
        >
          {{ buttonText }}
        </button> -->
        <div class="login-btn" @click="onSubmit">
          <button type="submit">Yakin</button>
          <img src="@/assets/img/login_btn_yinying.png" alt="" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Dialog, Icon } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword, validateVerifyCode } from '@/utils/validator'
import dialogVisible from '@/mixins/dialogVisible'
import { baseURL } from '@/config'

Vue.use(Icon)

Vue.use(Toast)
const maxTime = 150
let timer
const clearTimer = () => {
  timer && clearTimeout(timer) && (timer = null)
}
const titleMap = {
  forgetPassword: 'Lupa Password',
  messageLogin: 'Login dengan SMS',
  register: 'Daftar'
}
const buttonText = {
  forgetPassword: 'Yakin',
  messageLogin: 'Login',
  register: 'Daftar'
}
const apiMap = {
  forgetPassword: 'usdrerForgetPassword',
  messageLogin: 'usdrerLogin',
  register: 'usdrerRegistration'
}

const messageTypeMap = {
  forgetPassword: 3,
  messageLogin: 2,
  register: 1
}

export default {
  name: 'LoginForm',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mixins: [dialogVisible],
  data () {
    return {
      phone: '',
      password: '',
      password1: '',
      verifyCode: '',
      imgVerifyCode: '',
      isGetCode: true,
      countDown: maxTime,
      verifyImg: '',
      verifyImgCodes: [
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        }
      ],
      errorInfo: ''
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  computed: {

    title () {
      return titleMap[this.type]
    },
    buttonText () {
      return buttonText[this.type]
    },
    showPasswordInput () {
      return this.type === 'forgetPassword' || this.type === 'register'
    },
    tipText () {
      if (this.type === 'register') {
        return 'Kombinasi angka dan huruf tanpa karakter spesial8-13 digit'
      } else {
        return 'Kombinasi angka dan huruf tanpa karakter spesial8-13 digit'
      }
    }
  },
  watch: {
    phone (val) {
      if (val && val.length > 7) {
        this.getImgVerifyCode()
      }
    }
  },
  methods: {

    validatePhoneNumber () {
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      return true
    },
    validate () {
      if (!this.validatePhoneNumber()) return false
      if (this.showPasswordInput) {
        if (!this.password) {
          Toast(this.tipText)
          return false
        }
        if (this.password !== this.password1) {
          Toast('Kata sandi tidak konsisten')
          return false
        }
        if (!validatePassword(this.password)) {
          Toast('Masukkan Kombinasi angka dan huruf tanpa karakter spesial8-13 digit')
          return false
        }
      }
      if (!this.verifyCode) {
        Toast('Masukkan 4 digit angka kode verifikasi')
        return false
      }
      if (!validateVerifyCode(this.verifyCode)) {
        Toast('Kode verifikasi salah')
        return false
      }
      return true
    },
    startCountDown () {
      if (this.countDown <= 0) {
        this.reset()
      } else {
        timer = setTimeout(() => {
          this.countDown--
          this.startCountDown()
        }, 1000)
      }
    },
    getVerifyCode () {
      if (!this.validatePhoneNumber()) return
      if (this.type === 'register') {
        if (!validatePassword(this.password)) {
          Toast('Masukkan 8 digit angka password')
          return false
        }
      }
      if (this.showPasswordInput) {
        if (this.password !== this.password1) {
          Toast('Kata sandi tidak konsisten')
          return false
        }
      }

      if (this.imgVerifyCode.length !== 4) {
        Toast('Masukkan kode verifikasi yang tertera digambar')
        return
      }
      const postData = {
        usdrerPhone: this.phone,
        grdraphicCode: this.imgVerifyCode,
        tydrpe: messageTypeMap[this.type],
        smdrsType: 1
      }
      request.post('usdrercheckGraphic', postData).then(res => {
        this.imgVerifyCode && (postData.imdrgCode = this.imgVerifyCode)
        return request.post('usdrersendMessages', postData)
      }).then(() => {
        Toast('Kode verifikasi terkirim. Silakan cek')
        this.countDown = maxTime
        this.isGetCode = false
        this.startCountDown()
      }).catch(() => {
        this.imgVerifyCode = ''
        this.getImgVerifyCode()
      })
    },
    getImgVerifyCode () {
      this.$refs.verifyImg.src = `${baseURL}usdrergetGraphic?usdrerPhone=${this.phone}&t=${+Date.now()}`
    },
    reset () {
      clearTimer()
      this.isGetCode = true
      this.countDown = maxTime
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        usdrerPhone: this.phone,
        smdrs_Verificationcode: this.verifyCode,
        smdrsType: 1
      }
      if (this.type === 'forgetPassword') {
        postData.usdrerPassword = this.password
      } else if (this.type === 'messageLogin') {
        postData.lodrginType = 2
      } else if (this.type === 'register') {
        const ixabisoelinye = sessionStorage.getItem('ixabisoelinye')
        if (ixabisoelinye) {
          postData.ixabisoelinye = ixabisoelinye
        }
        const apitype = localStorage.getItem('apitype')
        if (apitype) {
          postData.apitype = apitype
        }
        postData.usdrerPassword = this.password
        postData.prdromotionChannel = 'AppStore'
      }
      request.post(apiMap[this.type], postData)
        .then(res => {
          if (this.type === 'forgetPassword') {
            const { query } = this.$route
            this.$router.push({
              path: '/login',
              query
            })
          } else if (this.type === 'register' || this.type === 'messageLogin') {
            this.$store.commit('recordInfo', {
              type: 'userInfo',
              value: res
            })
            localStorage.setItem('userInfo', JSON.stringify(res))
            this.addressBook()
            this.$router.push('/home')
          }
        })
        .finally(() => {
          this.flag = false
          this.verifyCode = ''
        })
    },

    refreshVerifyCode () {
      this.getImgVerifyCode()
    },
    onInput (index) {
      if (this.verifyImgCodes[index].value && index < 3) {
        console.log('onInput', index)
        this.$refs[`input${index + 1}`][0].focus()
      }
    },
    onDelete (index) {
      if (index > 0) {
        this.$refs[`input${index - 1}`][0].focus()
        console.log('onDelete', index)
      }
    },
    backLogon () {
      this.$router.back()
    }
  },
  deactivated () {
    this.reset()
  }
}
</script>

<style scoped lang="less">
.header-back-btn {
  width: 22px;
  padding-left: 30px;
  padding-top: 40px;
  margin-bottom: 20px;
}
.login {
  padding: 0 37px;
  padding-bottom: 100px;
  .logo {
    width: 120px;
    margin-bottom: 40px;
  }
  .title {
    font-weight: 600;
    font-size: 38px;
    margin-bottom: 40px;
  }
}

.input-block {
  div {
    color: #979696;
    font-size: 24px;
    margin-bottom: 14px;
  }
  &:nth-child(n + 2) {
    margin-top: 50px;
  }
  .img-code {
    display: flex;
    align-items: center;
    height: 88px;
    font-size: 26px;
    background: #ffffff;
    box-shadow: 0px 14px 37px 10px rgba(154, 182, 252, 0.28);
    border-radius: 20px;
    padding: 0 20px;
    padding-left: 0;
    .code {
      width: 180px;
      height: 80px;
      background: #f7f7f7;
      margin-left: auto;
      margin-bottom: 0;
    }
    .refresh {
      width: 30px;
      margin-left: 10px;
    }
    input {
      background: none;
      box-shadow: none;
      width: 420px;
      &::placeholder {
        font-size: 22px;
      }
    }
  }
  .input_box {
    box-shadow: 0px 14px 37px 10px rgba(154, 182, 252, 0.28);
    border-radius: 20px;
  }
  input {
    display: block;
    width: 100%;
    height: 88px;
    line-height: 88px;
    padding-left: 20px;
    border: none;
    box-sizing: border-box;
    font-size: 30px;
    background: #ffffff;
    border-radius: 20px;
    color: #333;
    &::placeholder {
      font-size: 23px;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}
.SMS-code {
  position: relative;
  margin-top: 50px;
  .title {
    color: #979696;
    font-size: 24px;
    margin-bottom: 14px;
    font-weight: 400;
  }
  input {
    position: absolute;
    border: 1px solid #ccc;
    left: 0;
    right: 0;
    opacity: 0;
    height: 100px;
  }
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    span {
      display: inline-block;
      border-radius: 20px;
      border: 2px solid #bbb;
      height: 100px;
      width: 100px;
      text-align: center;
      line-height: 100px;
    }
  }
}

.password {
  -webkit-text-security: disc;
}

.verify-code-block {
  position: relative;
  input {
    flex: 1;
    border-radius: 10px 0 0 10px;
  }
  input::placeholder {
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
  }
}

.verify-code-btn {
  font-weight: 600;
  font-size: 27px;
  color: #989797;
}

.count-down {
  padding-top: 0;
  line-height: 88px;
}

.confirm-btn {
  .submit-btn;
  width: 100%;
  margin-top: 70px;
}

.verify-title {
  line-height: 110px;
  font-size: 38px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.verify-body {
}

.verify-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;

  .verifyImg {
    width: 295px;
    height: 92px;
    margin-bottom: 10px;
    background: #4bc4b3;
  }
  .refresh {
    display: flex;
    align-items: center;
    margin-top: 15px;
    span {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
    }
    img {
      width: 20px;
      height: 25px;
      margin-left: 10px;
    }
  }
}

.refresh-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 100%;
  background-color: @theme-color;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
  }
}

.verify-input-box {
  display: flex;
  justify-content: space-between;
  margin: 0 50px;
  margin-top: 24px;

  div {
    flex: 1;

    &:nth-child(n + 2) {
      margin-left: 64px;
    }
  }

  input {
    width: 100%;
    height: 94px;
    line-height: 94px;
    padding: 0;
    padding-left: 20px;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 30px;
    color: #fff;
    background: #375169;
  }
  input::placeholder {
    font-weight: 500;
    font-size: 26px;
    color: #ffffff;
    opacity: 0.5;
  }

  .error-info {
    font-size: 24px;
    color: #fff;
    position: absolute;
    right: 80px;
    line-height: 88px;
  }
}

.handleBtn {
  display: flex;
  padding: 0 30px;
  gap: 20px;
  margin-top: 52px;
  margin-bottom: 84px;

  .verify-cancel-btn {
    .cancel-btn;
  }
  .verify-confirm-btn {
    .submit-btn;
  }
}

.verify-img-dialog {
  background: url("../assets/img/dialog_bg.png") no-repeat;
  background-size: 100% 100%;
}
.login-btn {
  position: relative;
  font-size: 24px;
  margin-top: 100px;
  button {
    .submit-btn;
    background: url("~@/assets/img/login_btn.png");
    background-size: 100% 100%;
    font-weight: 600;
    font-size: 34px;
  }
  img {
    position: absolute;
    bottom: -90px;
    height: 180px;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    opacity: 0.8;
  }
}
</style>
