<template>
  <loginForm type="register" />
</template>

<script>
import loginForm from '@/components/loginForm'

export default {
  name: 'Register',
  components: {
    loginForm
  }
}
</script>
